.formlogin {
    width: 40%;
    margin-left: 30%;
    margin-top: 10%;
    background-color: #f5f5f5;
}

.txtemail {
    width: 35dvw;
}

.txtpass {
    width: 35dvw;
}

.lgnbtn {
    background-color: #213555 !important;
}

.btn {
    background-color: #213555 !important;
}

.loginlogo {
    width: 200px;
    border-radius: 50%;
}