@media print {
    .print-only {
        width: 100%;
        height: 100%;
        display: block !important;
    }

    .no-print {
        display: none !important;
    }

    .print-chart-grid {
        /* border: 1px solid black;
        border-radius: 1px; */
        padding: 2px;
        width: 100%;
    }

    .print-chart-container {
        width: '50%';
        height: '50%';
        break-inside: avoid;
        /* Set the height to 100% of the available space in print mode */
        display: block !important;
        /* Adjust the height as needed for your print view */
    }

    .reportlabel {
        font-weight: 100 900;

    }
}