.body
{

  background-color: black;
}

.responsive{
  width: 80vw;
  height: 70vh;
  display: grid;
}

.App
{
  
}